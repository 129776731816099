<template>
	<el-form class="page" :model="mestaKoef" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('mesta.linija')" prop="id_vezane_linije">
					<select-linije @change="promena" class="full" :disabled="id > 0" v-model="mestaKoef.id_vezane_linije"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item>
					<el-table
							:data="mestaKoef.koeficijenti_mesta"
							v-loading="gridLoad"
							border
							stripe>
						<el-table-column
								prop="naziv"
								:label="$t('mesta.naziv')">
						</el-table-column>
						<el-table-column
								width="200"
								prop="koeficijent"
								:label="$t('mesta.koeficijent')">
							<template slot-scope="scope">
								<el-input-number :min="1" :precision="2" :step="0.1" v-model="scope.row.koeficijent"></el-input-number>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button v-loading="onSubmitLoading" :disabled="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'mesta-koef-edit',
	data() {
		return {
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			gridLoad: false,
			mestaKoef: {
				id_vezane_linije: null,
				koeficijenti_mesta: []
			},
			rules: {
				id_vezane_linije: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }]
			}
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('mesta.mestaKoef')
		});

		this.$utils('stopLoadingAfter', [this.getMesta()]);
	},
	methods: {
		getMesta() {
			if (!this.formCreate) {
				return this.$get('koefLinijeMesta', { id: this.id }).then(data => {
					this.mestaKoef = data;
				});
			}
		},
		promena() {
			this.gridLoad = true;
			this.$get('mestaZaLiniju', { id: this.mestaKoef.id_vezane_linije }).then(data => {
				data.forEach(m => {
					m.koeficijent = 1;
				});
				this.mestaKoef.koeficijenti_mesta = data;
				this.gridLoad = false;
			});
		},
		onSubmit() {
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				let data = JSON.stringify(this.mestaKoef);

				if (this.formCreate)
					this.$save('koefLinijeMesta', data);
				else
					this.$update('koefLinijeMesta', { id: this.id }, data);
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		}
	},
	components: {
		selectLinije: require('../../components/select/linije').default
	}
};
</script>
